var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-tabs',[_c('v-tab',[_vm._v("新增課程")]),_c('v-tab',[_vm._v("新增TODO")]),_c('v-tab-item',{attrs:{"tabindex":"1"}},[_c('v-row',{staticClass:"col-auto",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form"},[_c('validation-provider',{attrs:{"name":"課程名稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"items":_vm.allClass.map(function (x){ return x.name; }),"cache-items":"","hide-no-data":"","label":"課程名稱","prepend-icon":"mdi-text","required":"","counter":10,"error-messages":errors},on:{"change":_vm.changeTagColor},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.classDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.classDate=$event},"update:return-value":function($event){_vm.classDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"課程日期","prepend-icon":"mdi-calendar","readonly":"","required":""},model:{value:(_vm.classDate),callback:function ($$v) {_vm.classDate=$$v},expression:"classDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.classDate),callback:function ($$v) {_vm.classDate=$$v},expression:"classDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.classDate)}}},[_vm._v(" 確認 ")])],1)],1),_c('v-menu',{ref:"colorMenu",attrs:{"close-on-content-click":false,"return-value":_vm.tagColor,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.tagColor=$event},"update:return-value":function($event){_vm.tagColor=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"標籤顏色","prepend-icon":"mdi-circle","readonly":"","color":_vm.tagColor,"required":""},model:{value:(_vm.tagColor),callback:function ($$v) {_vm.tagColor=$$v},expression:"tagColor"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.colorMenu),callback:function ($$v) {_vm.colorMenu=$$v},expression:"colorMenu"}},[_c('v-color-picker',{attrs:{"no-title":"","scrollable":"","mode":"hexa"},model:{value:(_vm.tagColor),callback:function ($$v) {_vm.tagColor=$$v},expression:"tagColor"}}),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.colorMenu = false}}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.colorMenu.save(_vm.tagColor)}}},[_vm._v(" 確認 ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-dialog',{ref:"dialog2",attrs:{"return-value":_vm.startTime,"width":"290px"},on:{"update:returnValue":function($event){_vm.startTime=$event},"update:return-value":function($event){_vm.startTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"起始時間","prepend-icon":"mdi-clock-time-four-outline","readonly":"","error-messages":_vm.errors},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[(_vm.dialog2)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialog2 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog2.save(_vm.startTime)}}},[_vm._v(" OK ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-dialog',{ref:"dialog3",attrs:{"return-value":_vm.endTime,"width":"290px"},on:{"update:returnValue":function($event){_vm.endTime=$event},"update:return-value":function($event){_vm.endTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"結束時間","prepend-icon":"mdi-clock-time-four-outline","readonly":"","error-messages":_vm.errors},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.dialog3),callback:function ($$v) {_vm.dialog3=$$v},expression:"dialog3"}},[(_vm.dialog3)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialog3 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog3.save(_vm.endTime)}}},[_vm._v(" OK ")])],1):_vm._e()],1)],1)],1),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":invalid},on:{"click":_vm.add}},[_vm._v(" Add ")])],1)]}}])})],1)],1)],1),_c('v-tab-item',{attrs:{"tabindex":"2"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.taskDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.taskDate=$event},"update:return-value":function($event){_vm.taskDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"日期","prepend-icon":"mdi-calendar","readonly":"","required":""},model:{value:(_vm.taskDate),callback:function ($$v) {_vm.taskDate=$$v},expression:"taskDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.taskDate),callback:function ($$v) {_vm.taskDate=$$v},expression:"taskDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.taskDate)}}},[_vm._v(" 確認 ")])],1)],1),_c('v-text-field',{attrs:{"label":"TODO名稱","solo":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addTask($event)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-fade-transition',[(_vm.newTask)?_c('v-icon',{attrs:{"color":"success"},on:{"click":_vm.addTask}},[_vm._v(" mdi-check ")]):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.newTask),callback:function ($$v) {_vm.newTask=$$v},expression:"newTask"}}),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":_vm.invalid},on:{"click":_vm.addTask}},[_vm._v(" Add ")])],1)],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" 已新增 ")]),_c('v-card-text'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.redirect}},[_vm._v(" OK ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }